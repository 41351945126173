import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom'; // Импортируем useHistory для редиректа

const CustomModal = ({ show, handleClose }) => {
  const history = useHistory();

  const handleRedirect = () => {
    handleClose();
    history.push('/personal');
  };

  if (!show) {
    return null;
  }

  return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={handleClose}>
            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6647 16.7585L53.2344 51.3281L51.3138 53.2487L16.7442 18.679L18.6647 16.7585Z"
                    fill="#222731"/>
              <path d="M16.7428 51.3275L51.3125 16.7578L53.233 18.6783L18.6634 53.248L16.7428 51.3275Z" fill="#222731"/>
            </svg>
          </button>
          <h2>Укажите номер телефона</h2>
          <div className="modal-body">
            <p>Для корректной работы личного кабинета, пожалуйста, укажите <strong>ваш номер телефона</strong> в разделе
              «Персональные данные»</p>
            <div className="modal-footer">
              <button className="action-button" onClick={handleRedirect}>Перейти</button>
          </div>
          </div>
        </div>
      </div>
  );
};

export default CustomModal;

