import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';

export default function RoomListView({ recaptchaRef, handleRecaptchaChange, ...props }) {
  const [focus, setFocus] = useState(null);
  const [topicDropdown, setTopicDropdown] = useState(null);
  const [select, setSelect] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const renderError = (errText, err) => {
    if (err) {
      return <div className="error__message">{errText}</div>;
    }
  };

  const btnTopicDropdown = (id) => {
    id === topicDropdown ? setTopicDropdown(null) : setTopicDropdown(id);
  };

  return (
      <div className="m-width-100">
        {props.rooms &&
            props.rooms.length > 0 &&
            props.rooms.map((roomData, i) => {
              return (
                  <div className="d-flex text-center d-f-column" key={'roomData.nameForm' + '_' + i}>
                    <div
                        className={
                            'form__item ' +
                            (focus === roomData.id ? 'form-focused' : '') +
                            (roomData.name === 'recaptcha' ? 'recaptcha' : '') +
                            (roomData.err ? ' has-error ' : '') +
                            (roomData?.fields[0]?.type === 'checkbox' && roomData.fields.length > 1
                                ? ' form__item-box '
                                : '')
                        }>
                      {roomData.title && roomData.fields[0].type !== 'checkbox_simple' && (
                          <label
                              className={
                                  'form__label' +
                                  (roomData.fields[0].type === 'static_text' ? ' static_text' : '')
                              }
                              key={'roomData.label' + '_' + i}>
                            {roomData.title}
                            {roomData.fields[0].param.required && '*'}
                          </label>
                      )}
                      {roomData.fields?.map((inpData, inpI) => {
                        switch (inpData.type) {
                          case 'select' && 'dropdown':
                            return (
                                inpI === 0 && (
                                    <div
                                        className={
                                            'form__field form__select ' +
                                            (roomData.err ? 'has-error ' : '') +
                                            (topicDropdown === i ? 'active ' : '') +
                                            select
                                        }
                                        key={'roomData.inputForm' + '_' + inpI}>
                                      <div className="select__custom">
                                        <div
                                            onClick={() => btnTopicDropdown(i)}
                                            className={
                                                'select__custom-input d-flex align-items-center ' +
                                                (topicDropdown === roomData.id ? 'active' : '')
                                            }>
                                <span>
                                  {roomData.value !== '' ? roomData.value : roomData.placeholder}
                                </span>
                                          <div
                                              className={
                                                  'select__custom-arrow d-flex align-items-center justify-content-center ' +
                                                  (topicDropdown === i ? 'active' : '')
                                              }>
                                            <i
                                                className={
                                                    'select__arrow ' + (topicDropdown === i ? 'active' : '')
                                                }></i>
                                          </div>
                                        </div>
                                        <div
                                            className={
                                                'select__custom-dropdown ' + (topicDropdown === i ? 'active' : '')
                                            }>
                                          <div className="select__custom-dropdown-list">
                                            {roomData.fields?.map((inpDataS, inpIS) => (
                                                <div
                                                    onClick={() => {
                                                      setTopicDropdown(null);
                                                      props.handleroom(inpDataS.title, inpDataS.type, i, inpIS);
                                                    }}
                                                    className={'select__custom-dropdown-item '}
                                                    key={'roomData.dropdown' + '_' + inpIS}>
                                                  {inpDataS.title}
                                                </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      {renderError(roomData.errorText, roomData.err)}
                                    </div>
                                )
                            );

                          case 'checkbox':
                            return (
                                <div
                                    className={'checkbox__item d-flex ' + (roomData.err ? 'has-error' : '')}
                                    key={'roomData.checkbox' + '_' + inpI}>
                                  <label>
                                    <input
                                        type={inpData.type}
                                        name={inpData.value}
                                        onFocus={() => {
                                          setFocus(inpI);
                                          setTopicDropdown(null);
                                          props.setValidation(false, i);
                                        }}
                                        onChange={(e) => {
                                          props.handleroom(e.target.checked, inpData.type, i, inpI);
                                        }}
                                        checked={inpData.selected}
                                    />
                                    <div className="checkbox__decor"></div>
                                    <div
                                        className="checkbox__title"
                                        dangerouslySetInnerHTML={{ __html: inpData.title }}></div>
                                  </label>
                                  {renderError(roomData.errorText, roomData.err)}
                                </div>
                            );
                          case 'checkbox_simple':
                            return (
                                <div
                                    className={
                                        'checkbox__item checkbox_simple d-flex ' +
                                        (roomData.err ? 'has-error' : '')
                                    }
                                    key={'roomData.checkbox' + '_' + inpI}>
                                  <label>
                                    <input
                                        type="checkbox"
                                        name={inpData.value}
                                        id="checkbox"
                                        onFocus={() => {
                                          setFocus(inpI);
                                          setTopicDropdown(null);
                                          props.setValidation(false, i);
                                        }}
                                        onChange={(e) => {
                                          props.handleroom(e.target.checked, 'checkbox', i, inpI);
                                          props.setValidation(false, inpI);
                                        }}
                                        checked={inpData.selected}
                                    />
                                    <div className="checkbox__decor"></div>
                                    <div
                                        className="checkbox__title"
                                        dangerouslySetInnerHTML={{ __html: inpData.title }}></div>
                                  </label>
                                  {renderError(roomData.errorText, roomData.err)}
                                </div>
                            );
                          case 'textarea':
                            return (
                                <div
                                    className={['form__field ', roomData.err ? 'has-error' : ''].join(' ')}>
                          <textarea
                              type={roomData.type}
                              placeholder={roomData.placeholder}
                              value={roomData.value}
                              onFocus={() => {
                                setFocus(roomData.id);
                                setTopicDropdown(null);
                                props.setValidation(false, i);
                              }}
                              onChange={(e) => props.handleroom(e, roomData.type, i)}
                          />
                                  {renderError(roomData.errorText, roomData.err)}
                                </div>
                            );
                          case 'recaptcha':
                            return (
                                <div
                                    className={[
                                      'recaptcha form__field ',
                                      roomData.err ? 'has-error' : '',
                                    ].join(' ')}
                                    key={'roomData.inputForm' + '_' + inpI}>
                                  <div className="recaptcha-box v3">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={inpData.param.sitekey}
                                        size="invisible"
                                        onChange={handleRecaptchaChange}
                                    />
                                  </div>
                                  {renderError(roomData.errorText, roomData.err)}
                                </div>
                            );
                          case 'static_text':
                            break;
                          case 'phone':
                          case 'personal_phone':
                            return (
                                <div
                                    className={[
                                      'form__field ',
                                      inpData.name && inpData.name,
                                      roomData.err ? 'has-error' : '',
                                    ].join(' ')}
                                    key={'roomData.inputForm' + '_' + inpI}>
                                  <InputMask
                                      type="phone"
                                      mask="+79999999999"
                                      alwaysShowMask={false}
                                      value={inpData.value}
                                      onFocus={() => {
                                        setFocus(i);
                                        setTopicDropdown(null);
                                        props.setValidation(false, i);
                                      }}
                                      onChange={(e) => props.handleroom(e, inpData.type, i, inpI)}
                                  />
                                  {renderError(roomData.errorText, roomData.err)}
                                </div>
                            );
                          default:
                            return (
                                <div
                                    className={[
                                      'form__field ',
                                      inpData.name && inpData.name,
                                      roomData.err ? 'has-error' : '',
                                    ].join(' ')}
                                    key={'roomData.inputForm' + '_' + inpI}>
                                  <input
                                      type={inpData.type}
                                      value={inpData.value}
                                      name={inpData.name}
                                      onFocus={() => {
                                        setFocus(i);
                                        setTopicDropdown(null);
                                        props.setValidation(false, i);
                                      }}
                                      onChange={(e) => props.handleroom(e, inpData.type, i, inpI)}
                                  />
                                  {(inpData.name === 'password' || inpData.name === 'password_confirm') && (
                                      <span
                                          className={
                                              'togglePass ' + (inpData.type === 'password' ? '' : 'active')
                                          }
                                          onClick={() => {
                                            props.togglePass(i, inpI);
                                          }}></span>
                                  )}
                                  {renderError(roomData.errorText, roomData.err)}
                                </div>
                            );
                        }
                      })}
                    </div>
                    {roomData.comments !== null && roomData.comments !== '' && (
                        <div
                            className="form__item form__comments"
                            dangerouslySetInnerHTML={{ __html: roomData.comments }}></div>
                    )}
                  </div>
              );
            })}
      </div>
  );
}
