import React, { useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

export default function RoomListView({ ...props }) {
  const [focus, setFocus] = useState(null);
  const [error, setError] = useState(null);
  const [topicDropdown, setTopicDropdown] = useState(null);

  const renderError = (errText, err) => {
    if (err) {
      return <div className="error__message">{errText}</div>;
    }
  };
  const btnTopicDropdown = (id) => {
    id === topicDropdown ? setTopicDropdown(null) : setTopicDropdown(id);
  };
  return (
    <div className="d-flex d-f-column m-f-column m-width-100">
      {props.rooms.map((roomData, i) => {
        return (
          <div
            className={
              'form__item ' +
              (focus === roomData.id ? 'form-focused' : '') +
              (roomData.name === 'recaptcha' ? 'recaptcha' : '') +
              (roomData.err ? ' has-error ' : '')
            }
            key={roomData.nameForm + '_' + i}>
            <div className="d-flex d-f-between d-aling-start">
              {roomData.type !== 'checkbox' && roomData.label && (
                <label className="form__label">{roomData.label}</label>
              )}
              {roomData.btnForgonLogin && (
                <a
                  href="#"
                  className="form__btnForgonLogin"
                  onClick={() => (props.forPassword(3), props.setErrTextApi(''))}>
                  Восстановить пароль
                </a>
              )}
            </div>
            {(() => {
              switch (roomData.type) {
                case 'select':
                  return (
                    <div
                      className={
                        'form__field form__select ' +
                        (roomData.err ? 'has-error ' : '') +
                        (topicDropdown === roomData.id ? 'active' : '')
                      }>
                      <div className="select__custom">
                        <div
                          onClick={() => btnTopicDropdown(roomData.id)}
                          className={
                            'select__custom-input d-flex align-items-center ' +
                            (topicDropdown === roomData.id ? 'active' : '')
                          }>
                          <span>
                            {roomData.value != '' ? roomData.value : roomData.placeholder}
                          </span>
                          <div
                            className={
                              'select__custom-arrow d-flex align-items-center justify-content-center ' +
                              (topicDropdown === roomData.id ? 'active' : '')
                            }>
                            <i
                              className={
                                'select__arrow ' + (topicDropdown === roomData.id ? 'active' : '')
                              }></i>
                          </div>
                        </div>
                        <div
                          className={
                            'select__custom-dropdown ' +
                            (topicDropdown === roomData.id ? 'active' : '')
                          }>
                          <div className="select__custom-dropdown-list">
                            {roomData.item?.map((selTit, index) => (
                              <div
                                onClick={() => {
                                  props.handleroom(selTit.name, roomData.type, i, selTit.id);
                                  setTopicDropdown(null);
                                }}
                                className={'select__custom-dropdown-item '}>
                                {selTit.name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {renderError(roomData.errorText, roomData.err)}
                    </div>
                  );
                case 'checkbox':
                  return (
                    <div className={'checkbox__item d-flex ' + (roomData.err ? 'has-error' : '')}>
                      <label>
                        <input
                          type={roomData.type}
                          placeholder={roomData.placeholder}
                          name={roomData.name}
                          id="checkbox"
                          onFocus={() => {
                            setFocus(roomData.id);
                            setTopicDropdown(null);
                            props.setValidation(false, i);
                          }}
                          onChange={(e) => {
                            props.handleroom(e.target.checked, roomData.type, i);
                            props.setValidation(false, i);
                          }}
                          checked={roomData.value}
                        />
                        <div className="checkbox__decor"></div>
                        <div
                          className="checkbox__title"
                          dangerouslySetInnerHTML={{ __html: roomData.label }}></div>
                      </label>
                      {renderError(roomData.errorText, roomData.err)}
                    </div>
                  );
                case 'textarea':
                  return (
                    <div className={['form__field ', roomData.err ? 'has-error' : ''].join(' ')}>
                      <textarea
                        type={roomData.type}
                        placeholder={roomData.placeholder}
                        value={roomData.value}
                        onFocus={() => {
                          setFocus(roomData.id);
                          setTopicDropdown(null);
                          props.setValidation(false, i);
                        }}
                        onChange={(e) => props.handleroom(e, roomData.type, i)}
                      />
                      {renderError(roomData.errorText, roomData.err)}
                    </div>
                  );
                case 'recaptcha':
                  return (
                    <div
                      className={['recaptcha form__field ', roomData.err ? 'has-error' : ''].join(
                        ' ',
                      )}>
                      <div className="recaptcha-title">{roomData.placeholder}</div>
                      <div className="recaptcha-box">
                        <ReCAPTCHA
                          sitekey={roomData.sitekey}
                          size="invisible"
                          onChange={(e) => props.handleroom(e, roomData.type, i)}
                        />
                      </div>
                      {renderError(roomData.errorText, roomData.err)}
                    </div>
                  );
                default:
                  return (
                    <div className={['form__field ', roomData.err ? 'has-error' : ''].join(' ')}>
                      <input
                        type={roomData.type}
                        placeholder={roomData.placeholder}
                        value={roomData.value}
                        onFocus={() => {
                          setFocus(roomData.id);
                          setTopicDropdown(null);
                          props.setValidation(false, i);
                        }}
                        onChange={(e) => props.handleroom(e, roomData.type, i)}
                      />
                      {roomData.togglePass && (
                        <span
                          className={'togglePass ' + (roomData.toggleActive && 'active')}
                          onClick={() => {
                            props.togglePass(i, roomData.type);
                          }}></span>
                      )}
                      {renderError(roomData.errorText, roomData.err)}
                    </div>
                  );
              }
            })()}
          </div>
        );
      })}
    </div>
  );
}
